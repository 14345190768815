

















































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import { validationMixin } from 'vuelidate';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { namespace } from 'vuex-class';
import User from '@/models/User';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';

const AuthStore = namespace('auth');
const UserStore = namespace('user');

@Component({
  components: {RJTextField},
  mixins: [validationMixin],
    validations: {
        data: {
            passwordOld: {required},
            password: {required},
            passwordCopy: {
                required,
                sameAsPassword: sameAs('password'),
            },
        },
    },
})
export default class UserPasswordComponent extends mixins(ErrorMessageHandlerMixin) {

    @AuthStore.Getter('user')
    private user!: User;
    @UserStore.Action('changePasswordAction')
    private changePasswordAction!: (payload: { user: User, passwordOld: string, password: string }) => Promise<void>;

    public data: { passwordOld?: string, password?: string, passwordCopy?: string } =
        {
            passwordOld: undefined,
            password: undefined,
            passwordCopy: undefined,
        };

    public formWasSubmitted: boolean = false;

    /**
     *  All password criterias that are necessary to add a new password
     */
    public passwordCriteria: Array<{ fullfilled: boolean, regEx: RegExp, display: string }> = [];

    get isButtonDisabled(): boolean {
        return (this.$v.data!.$anyDirty || this.formWasSubmitted) && (!this.arePasswordCriteriaFullfilled || this.$v.data!.$invalid);
    }

    get arePasswordCriteriaFullfilled(): boolean {
        return this.passwordCriteria.every((criterion) => criterion.fullfilled);
    }

    public created() {
        this.setPasswordCriteria();
    }

    /**
     * Sets the password criteria
     */
    private setPasswordCriteria() {
        this.passwordCriteria = [
            {
                fullfilled: false,
                regEx: /[a-z]+/,
                display: this.$t('SET_PASSWORD.CRITERIA.LOWER_CASE').toString(),
            },
            {
                fullfilled: false,
                regEx: /[A-Z]+/,
                display: this.$t('SET_PASSWORD.CRITERIA.UPPER_CASE').toString(),
            },
            {
                fullfilled: false,
                regEx: /[0-9]+/,
                display: this.$t('SET_PASSWORD.CRITERIA.ONE_NUMBER').toString(),
            },
            {
                fullfilled: false,
                regEx: /.{8,}/,
                display: this.$t('SET_PASSWORD.CRITERIA.AT_LEAST').toString(),
            },
        ];
    }

    /**
     * Validate the password input regarding the password criteria a base criteria (e. g. required)
     * @param value
     */
    public validatePasswordInput(value: string) {
        // base criteria
        this.triggerValidation('data.password');
        // special password criteria
        this.passwordCriteria.forEach((criterion) => criterion.fullfilled = criterion.regEx.test(value));
    }

    /**
     * Event handler for the password change
     */
    public async onSubmit() {
        this.formWasSubmitted = true;
        this.$v.data!.$touch();

        if (!this.$v.data!.$invalid && this.arePasswordCriteriaFullfilled) {
            try {
                await this.changePasswordAction({
                    user: this.user,
                    passwordOld: this.data.passwordOld!,
                    password: this.data.password!,
                });
                this.$notifySuccessSimplified('USER_PASSWORD.NOTIFICATIONS.SET_PASSWORD_SUCCESS');
            } catch (e) {
                if (this.$te(`USER_PASSWORD.NOTIFICATIONS.${e.status}`)) {
                    this.$notifyErrorSimplified(`USER_PASSWORD.NOTIFICATIONS.${e.status}`);
                } else {
                    this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
                }
            } finally {
                Object.keys(this.data).forEach((key) => this.data[key] = null);
                this.passwordCriteria.forEach((criterion) => criterion.fullfilled = false);
                this.formWasSubmitted = false;
                this.$v.data!.$reset();
            }
        }
    }
}
